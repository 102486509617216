@use '../../../../styles/_global-variables.scss' as global;
@use '../../../../styles/_portal-variables.scss' as tresplay;

// ESTILS GENÈRICS
.boto {
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 700;
    font-size: var(--body_s);
    line-height: 1;
    align-items: center;
    overflow: hidden;
    border: none;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    cursor: pointer;
    padding: 0 16px;
    height: 40px;
}
.l {
    font-size: var(--body_l);
    height: 54px;
    .icona {
        width: 24px;
        height: 24px;
    }
}
.icona {
    margin-left: 0;
    margin-right: 8px;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    vertical-align: baseline;
    position: relative;
    &.dreta {
        margin-left: 8px;
        margin-right: 0;
    }
}

.play .icona {
    width: 24px;
    height: 24px;
}

// TEMES
.boto.audiodescripcio {
    text-transform: none;
    font-weight: normal;
    border: 1px solid var(--clr_trans_0);
    .dreta {
        margin-left: 2px;
    }
}
.boto.ple {
    background-color: var(--clr_portal_100);
    color: var(--clr_trans_0);

    &:hover,
    &:focus {
        background-color: var(--clr_portal_80);
    }
    &:active {
        background-color: var(--clr_portal_100);
    }
}
.boto.contorn {
    border: 2px solid var(--clr_portal_100);
    background-color: var(--clr_trans_0);
    color: var(--clr_portal_100);
    .icona {
        filter: invert(38%) sepia(92%) saturate(4011%) hue-rotate(342deg) brightness(83%) contrast(114%);
    }
    &:hover,
    &:focus {
        color: var(--clr_portal_80);
        border-color: var(--clr_portal_80);
        .icona {
            filter: invert(17%) sepia(20%) saturate(5352%) hue-rotate(337deg) brightness(106%) contrast(119%);
        }
    }
    &:active {
        color: var(--clr_trans_0);
        background-color: var(--clr_portal_80);
        border-color: var(--clr_portal_80);
        .icona {
            filter: brightness(0) invert(1);
        }
    }
    &.fosc:not(:active) {
        color: var(--clr_trans_0);
        background-color: tresplay.$colorFons90;
        .icona {
            filter: brightness(0) invert(1);
        }
    }
}
.boto.transparent {
    border: 1px solid currentColor;
    color: var(--clr_trans_20);
    background-color: rgba(0, 0, 0, 0.15);
    .icona {
        filter: brightness(0) invert(1);
    }
    &:hover {
        color: var(--clr_trans_0);
    }
}

.boto.enllac {
    border-bottom: none;
    padding: 0;
    height: auto;
    text-transform: none;
}

.boto.hiddenLabel {
    text-transform: none;
    font-weight: normal;
    position: relative;
    .icona {
        filter: brightness(0) invert(1);
    }
    .label {
        display: none;
    }
    &:hover,
    &:focus {
        .label {
            display: inline;
        }
    }
}
.boto.debug {
    background-color: var(--clr_portal_100);
    color: #fff;
    font-size: 0.8rem;
    padding: 8px;
    height: auto;
}

.boto span {
    position: relative;
}

.T-cercador {
    font-size: var(--tv-m2);
    font-weight: normal;
    text-transform: none;
    color: var(--clr_trans_20);
    &.contorn {
        border: 1px solid var(--clr_trans_20);
        color: var(--clr_trans_20);
        padding: 5px 10px 4px;
        &:hover,
        &:focus {
            color: var(--clr_trans_40);
        }
        &:active {
            color: var(--clr_trans_0);
            border-color: var(--clr_trans_100);
            background-color: var(--clr_trans_100);
        }
    }
}
.T-generic {
    color: var(--clr_trans_0);
    &.contorn {
        border: 2px solid var(--clr_trans_0);
        &:hover,
        &:focus {
            color: var(--clr_trans_40);
        }
        &:active {
            color: var(--clr_trans_0);
            border-color: var(--clr_trans_100);
            background-color: var(--clr_trans_100);
        }
    }
}

.tira {
    margin: 0 16px 40px 16px;
    position: relative;
    left: 50%;
    transform: translateX(calc(-50% - 16px));
    padding: 12px 25px;
    background-color: var(--clr_portal_100);
    color: var(--clr_trans_0);
    font-size: var(--tv-m2);
    text-transform: uppercase;
    font-weight: 700;
    height: auto;
    line-height: 1.5;
    &::before {
        content: '';
        position: absolute;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
        z-index: -1;
    }

    .icona {
        filter: brightness(0) invert(1);
    }

    &:hover {
        &::before {
            background: var(--clr_trans_95);
        }

        color: var(--clr_trans_0);
    }
}

.T-actualitat {
    background: radial-gradient(58.5% 58.5% at 50% 50%, #ef7d00 0%, rgba(239, 125, 0, 0) 100%),
        conic-gradient(from 0deg at 50% 50%, #ef7d00 0deg, #c84191 161.25deg, #c64832 256.87deg, #ef7d00 360deg);
}

.T-esports {
    background: radial-gradient(59.5% 59.5% at 50% 50%, #ffcc00 0%, rgba(255, 204, 0, 0) 100%),
        conic-gradient(from 0deg at 50% 50%, #ffcc00 0deg, #c84191 161.25deg, #efba00 256.87deg, #ffcc00 360deg);
}

.T-arts {
    background: linear-gradient(140deg, #01f890 10%, #27b3e8 25%, #27a8e8 70%, #3f20fa 100%);
}

.T-cuines {
    background: linear-gradient(340deg, #8541c8 0%, #ae3f98 20%, #c23321 60%, #682d3a 100%);
}

.T-sx3 {
    background: linear-gradient(355deg, #2c76ff 0%, #2769e8 5%, #4f10ac 45%, #4f10ac 100%);
}

.T-podcasts {
    background: linear-gradient(111deg, #979797 0%, #ffcc00 70%, #979797 100%);
}

.T-joves {
    background: radial-gradient(59.5% 59.5% at 50% 50%, #b9d13c 0%, rgba(185, 209, 60, 0) 100%),
        conic-gradient(from 0deg at 50% 50%, #b9d13c 0deg, #20fa91 153.75deg, #ef7d00 256.87deg, #b9d13c 360deg);
}

.T-eva {
    background: linear-gradient(168deg, #ffffff 0%, #ffffff 35%, #d74494 85%, #d74494 100%);
}

//HOVERS ICONES
.T-actualitat,
.T-esports,
.T-arts,
.T-podcasts,
.T-eva {
    .icona {
        filter: brightness(0) invert(0);
    }
    &:hover .icona {
        filter: brightness(0) invert(1);
    }
}

// HOVER FONS NEGRE

.T-actualitat,
.T-esports,
.T-arts,
.T-cuines,
.T-sx3,
.T-eva,
.T-podcasts {
    position: relative;

    &:hover::before {
        content: '';
        position: absolute;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        top: 2px;
        left: 2px;
        // z-index: -1;
        background-color: black;
    }
}

// EXCEPCIONS AMB TEXT NEGRE

.T-actualitat,
.T-esports,
.T-arts,
.T-eva,
.T-podcasts {
    svg path {
        fill: #000;
    }
    .label {
        color: #000;
    }
    &:hover .label {
        color: #fff;
    }
    &:hover svg path {
        fill: #fff;
    }
}

.T-onboarding {
    background: var(--clr_trans_0);
    color: var(--clr_portal_100);
    border-radius: 54px;
    font-size: 18px;
    &:hover {
        opacity: 0.8;
    }
    @media #{global.$VP_LARGE_NEGATION} {
        font-size: 14px;
    }
}

.az {
    margin: 0 10px;
    height: 42px;
    padding: 0;
    .icona {
        margin-right: 0;
        width: 28px;
    }

    .label {
        font-size: var(--body_m);
        padding-top: 7px;
    }

    &:focus {
        outline: none;
    }
}

// MIDA GRAN A PARTIR DE 1920px

@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .boto {
        height: 56px;
        font-size: var(--body_l);
        padding: 0 24px;
    }

    .icona {
        transform: scale(1.5);
        margin-right: 16px;
        &.dreta {
            margin-left: 16px;
            margin-right: 0;
        }
    }
}
