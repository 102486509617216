@use '../../../styles/noticies/_portal-variables.scss' as tresplay;
@use '../../../styles/noticies/_global-variables.scss' as global;

.loadingContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;

    .loadingCenter {
        position: relative;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        text-align: center;

        h2 {
            color: #fff;
            font-size: var(--headline_s);
            font-weight: 300;
            margin-bottom: 22px;
            margin-top: 22px;
        }
        p {
            font-size: var(--body_m);
            font-weight: 300;
            margin-bottom: 22px;
        }
        .boto {
            margin-top: 36px;
        }
    }

    .loadingBg {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 20px;
    }
}

.loadingSkeleton {
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    display: block;
    .skeletonContent {
        margin-top: 100px;
    }
}

.spinner {
    width: 117px;
    height: 117px;

    .cap {
        stroke: tresplay.$colorBase80;
    }

    .color1 {
        stop-color: tresplay.$colorBase80;
        stop-opacity: 1;
    }
    .color2 {
        stop-color: tresplay.$colorBase160;
        stop-opacity: 1;
    }
    .color3 {
        stop-color: tresplay.$colorBase70;
        stop-opacity: 0.3;
    }
    .color4 {
        stop-color: tresplay.$colorBase180;
        stop-opacity: 0;
    }
}
