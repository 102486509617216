@use '../../../styles/noticies/_global-variables.scss' as global;

.redlNotRenderableInfo {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 70%;
    height: auto; /* Height will adjust to content */
    background-color: red;
    color: white;
    align-items: center;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
    min-height: max-content;
    overflow: auto;
    padding: 0.5rem;
    z-index: 201;
}

.redlNotRenderableInfo__text {
    font-size: 1rem;
    font-weight: 100;
    padding: 0.5rem;
}

.closeButton {
    background: transparent;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #333;
}

.redlNotRenderableInfo {
    opacity: 1;
    transition: opacity 0.3s ease;
    pointer-events: none;
    pointer-events: auto;
}

.redlNotRenderableInfo.fading {
    opacity: 0;
}

.redlNotRenderableInfo.notVisible {
    display: none;
}