@use 'styles/_portal-variables.scss' as tresplay;

.linkqa {
    padding: 8px 20px;
    display: block;
    &:hover {
        color: var(--clr_portal_100);
        text-decoration: underline;
    }
}
