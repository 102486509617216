@use '../../../styles/noticies/_global-variables.scss' as global;

.pre_notfound {
    background-color: var(--color-background-skeleton-neutral-lighter);
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing-space-750);
}
.notfound {
    display: flex;
    width: 804px;
    padding: var(--spacing-space-1000) 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
}
.codi {
    padding-top: var(--spacing-space-300);
    color: var(--color-text-disabled);
    text-align: center;
    font-size: var(--font-size-heading-xxl);
    font-weight: 500;
    line-height: var(--font-height-heading-xxl);
}
.titol {
    color: var(--color-text-default);
    text-align: center;
    font-size: var(--font-size-heading-xxl);
    font-weight: 500;
    line-height: var(--font-height-heading-xxl);
    padding-top: var(--spacing-space-200);
}

.text {
    color: var(--color-text-default);
    text-align: center;
    font-size: var(--font-size-body-xl);
    font-weight: 400;
    line-height: var(--font-height-body-xl);
    padding-top: var(--spacing-space-550);
    padding-bottom: var(--spacing-space-500);
}

@media #{global.$VP_ALL_MOBILE} {
    .pre_notfound {
        margin-bottom: var(--spacing-space-400);
    }
    .notfound {
        padding: var(--spacing-space-700) var(--spacing-space-200);
    }
    .codi {
        font-size: var(-font-size-800);
    }
    .titol {
        padding-top: var(--spacing-space-100);
    }
    .text {
        padding-top: var(--spacing-space-400);
        padding-bottom: var(--spacing-space-300);
    }
    .boto {
        padding: var(--spacing-space-100) var(--spacing-space-200);
        height: 40px;
    }
}
