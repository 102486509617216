@use 'utils/pxRem' as mixins;
@use './global-variables' as global;

.noticies {
    @font-face {
        font-family: 'Museo Slab 900';
        src: url(/_resources/fonts/MuseoSlab-900.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/MuseoSlab-900.woff?v=1.2502.4) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Thin.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Thin.woff?v=1.2502.4) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Light.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Light.woff?v=1.2502.4) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Regular.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Regular.woff?v=1.2502.4) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Medium.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Medium.woff?v=1.2502.4) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Bold.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Bold.woff?v=1.2502.4) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-Black.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Black.woff?v=1.2502.4) format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: block;
    }

    // ITALIC

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-ThinItalic.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-ThinItalic.woff?v=1.2502.4) format('woff');
        font-weight: 100;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-LightItalic.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-LightItalic.woff?v=1.2502.4) format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-RegularItalic.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-RegularItalic.woff?v=1.2502.4) format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-MediumItalic.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-MediumItalic.woff?v=1.2502.4) format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-BoldItalic.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-BoldItalic.woff?v=1.2502.4) format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Bw 3cat';
        src: url(/_resources/fonts/Bw3Cat-BlackItalic.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-BlackItalic.woff?v=1.2502.4) format('woff');
        font-weight: 900;
        font-style: italic;
        font-display: block;
    }

    // DOTTED

    @font-face {
        font-family: 'Bw 3cat dotted';
        src: url(/_resources/fonts/Bw3Cat-Dotted.woff2?v=1.2502.4) format('woff2'),
            url(/_resources/fonts/Bw3Cat-Dotted.woff?v=1.2502.4) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    // MIDES TEXT
    --font-family: 'Bw 3cat', Helvetica, Arial, sans-serif;
    --font-family-dotted: 'Bw 3cat dotted', Helvetica, Arial, sans-serif;

    --font-size-50: #{mixins.pxRem(12)};
    --font-size-100: #{mixins.pxRem(14)};
    --font-size-200: #{mixins.pxRem(16)};
    --font-size-300: #{mixins.pxRem(18)};
    --font-size-400: #{mixins.pxRem(20)};
    --font-size-450: #{mixins.pxRem(23)};
    --font-size-500: #{mixins.pxRem(26)};
    --font-size-550: #{mixins.pxRem(29)};
    --font-size-600: #{mixins.pxRem(32)};
    --font-size-650: #{mixins.pxRem(36)};
    --font-size-700: #{mixins.pxRem(41)};
    --font-size-800: #{mixins.pxRem(46)};
    --font-size-850: #{mixins.pxRem(52)};
    --font-size-900: #{mixins.pxRem(58)};
    --font-size-1000: #{mixins.pxRem(66)};

    --font-size-heading-xxl: var(--font-size-1000);
    --font-size-heading-xl: var(--font-size-900);
    --font-size-heading-l: var(--font-size-800);
    --font-size-heading-m: var(--font-size-700);
    --font-size-heading-s: var(--font-size-600);
    --font-size-body-xxl: var(--font-size-500);
    --font-size-body-xl: var(--font-size-450);
    --font-size-body-l: var(--font-size-400);
    --font-size-body-m: var(--font-size-300);
    --font-size-body-s: var(--font-size-200);
    --font-size-body-xs: var(--font-size-100);
    --font-size-body-xxs: var(--font-size-50);

    // COLORS TRANSVERSALS 3CAT - DESCOMENTAR A MESURA QUE ELS FEM SERVIR
    --clr_trans_0: #fff;
    --clr_trans_10: #e5e5e5;
    --clr_trans_20: #ccc;
    --clr_trans_30: #b2b2b2;
    --clr_trans_40: #999;
    // --clr_trans_50: grey;
    // --clr_trans_60: #666;
    --clr_trans_70: #4d4d4d;
    // --clr_trans_80: #333;
    --clr_trans_90: #1a1a1a;
    // --clr_trans_95: #111;
    // --clr_trans_100: #000;
    --clr_portal_digital: #f61e50;

    // COLORS
    --3cat-100: #fce8ed;
    --3cat-200: #f8becc;
    --3cat-350: #f95d7f;
    --3cat-400: #ea5374;
    --3cat-500: #de2c53;
    --3cat-700: #b6163c;
    --3cat-800: #5b0b1e;

    --3cat-info-100: #ffefe6;
    --3cat-info-200: #ffd4ba;
    --3cat-info-400: #ffa066;
    --3cat-info-500: #ff8135;
    --3cat-info-600: #ff6508;
    --3cat-info-700: #c74e05;
    --3cat-info-800: #662600;

    --esports-100: #fffbe5;
    --esports-200: #fff1b2;
    --esports-400: #ffe066;
    --esports-500: #ffcc00;
    --esports-700: #cba301;
    --esports-800: #665300;

    --temps-neutral-200: #cccccc;
    --temps-neutral-300: #8a8886;
    --temps-neutral-500: #4c4c4c;
    --temps-neutral-800: #333333;
    --temps-primary-500: #ff5a00;
    --temps-secondary-500: #0da4ff;

    --neutrals-0: #ffffff;
    --neutrals-100: #f0f3f4;
    --neutrals-200: #dce3e5;
    --neutrals-400: #a1aeb2;
    --neutrals-500: #73868c;
    --neutrals-700: #455054;
    --neutrals-800: #2b373b;
    --neutrals-900: #21292c;
    --neutrals-1000: #111617;

    --semantic-alert-100: #fff6e5;
    --semantic-alert-200: #ffe4b2;
    --semantic-alert-300: #ffc966;
    --semantic-alert-500: #ffa600;
    --semantic-alert-700: #996300;
    --semantic-alert-800: #664200;

    --semantic-danger-100: #ffe5e5;
    --semantic-danger-200: #ffb2b2;
    --semantic-danger-300: #ff6666;
    --semantic-danger-500: #ff0000;
    --semantic-danger-700: #990000;
    --semantic-danger-800: #660000;

    --semantic-success-100: #e9fbea;
    --semantic-success-200: #bef3c0;
    --semantic-success-300: #7de881;
    --semantic-success-500: #27d82e;
    --semantic-success-700: #17821b;
    --semantic-success-800: #0f5712;

    // COLOR TEXT
    --color-text-accent: var(--neutrals-700);
    --color-text-default: var(--neutrals-1000);
    --color-text-default-inverse: var(--neutrals-0);
    --color-text-link-neutral-default: var(--neutrals-1000);
    --color-text-link-neutral-default-inverse: var(--neutrals-0);
    --color-text-link-neutral-accent-inverse: var(--neutrals-400);
    --color-text-link-neutral-hover: var(--neutrals-700);
    --color-text-link-3catinfo: var(--3cat-info-700);
    --color-text-link-3catinfo-default-inverse: var(--3cat-info-500);
    --color-text-link-3catinfo-default-inverse-hover: var(--neutrals-0);
    --color-text-link-3catinfo-hover: var(--3cat-info-500);
    --color-text-title: var(--neutrals-900);
    --color-text-title-inverse: var(--neutrals-0);
    --color-text-disabled: var(--neutrals-500);

    // COLOR BACKGROUND
    --color-background-button-3catinfo-default: var(--3cat-info-500);
    --color-background-button-3catinfo-light: var(--3cat-info-100);
    --color-background-button-3catinfo-hover: var(--3cat-info-600);
    --color-background-button-3catinfo-hover-inverse: var(--3cat-info-400);
    --color-background-button-neutral-default: var(--neutrals-1000);
    --color-background-button-neutral-lighter: var(--neutrals-100);
    --color-background-button-neutral-disabled: var(--neutrals-200);
    --color-background-esports-skeleton-default: var(--esports-500);
    --color-background-skeleton-neutral-default: var(--neutrals-0);
    --color-background-skeleton-neutral-lighter: var(--neutrals-100);
    --color-background-skeleton-neutral-light: var(--neutrals-200);
    --color-background-skeleton-neutral-medium: var(--neutrals-700);
    --color-background-skeleton-neutral-bold: var(--neutrals-900);
    --color-background-skeleton-neutral-bolder: var(--neutrals-1000);
    --color-background-skeleton-3catinfo-default: var(--3cat-info-500);
    --color-background-skeleton-3catinfo-default-inverse: var(--3cat-info-500);
    --color-background-skeleton-3catinfo-bold: var(--3cat-info-700);
    --color-background-skeleton-3catinfo-bolder: var(--3cat-info-800);
    --neutrals-background-20: #ebf0f4;

    // COLOR BORDER
    --color-stroke-3catinfo-default: var(--3cat-info-700);
    --color-stroke-3catinfo-default-inverse: var(--3cat-info-500);
    --color-stroke-3catinfo-accent: var(--3cat-info-600);
    --color-stroke-3catinfo-hover: var(--3cat-info-500);
    --color-stroke-neutral-inverse: var(--neutrals-0);
    --color-stroke-neutral-disable: #a1aeb2;
    --color-stroke-neutral-default-inverse: #4d4d4d;
    --color-stroke-neutral-disable-inverse: var(--neutrals-700);
    --color-stroke-neutral-bolder: var(--neutrals-900);
    --color-stroke-neutral-default: var(--neutrals-200);
    --color-stroke-neutral-medium: var(--neutrals-700);
    --color-stroke-semantic-accesibility-focus: #066ba8;

    // LINE HEIGHT
    --font-height-height-100: 1.42857; //body-xs
    --font-height-height-200: 1.375; //body-s
    --font-height-height-300: 1.44444; //body-m
    --font-height-height-400: 1.4; //body-l
    --font-height-height-450: 1.26087; //body-xl
    --font-height-height-500: 1.23077; //body-xxl
    --font-height-height-600: 1.25; //heading-s
    --font-height-height-700: 1.19512; //heading-m
    --font-height-height-800: 1.21739; //heading-l
    --font-height-height-900: 1.17241; //heading-xl
    --font-height-height-1000: 1.21212; //heading-xxl

    --font-height-heading-xxl: var(--font-height-height-1000);
    --font-height-heading-xl: var(--font-height-height-900);
    --font-height-heading-l: var(--font-height-height-800);
    --font-height-heading-m: var(--font-height-height-700);
    --font-height-heading-s: var(--font-height-height-600);
    --font-height-body-xxl: var(--font-height-height-500);
    --font-height-body-xl: var(--font-height-height-450);
    --font-height-body-l: var(--font-height-height-400);
    --font-height-body-m: var(--font-height-height-300);
    --font-height-body-s: var(--font-height-height-200);
    --font-height-body-xs: var(--font-height-height-100);

    // GAP
    --spacing-space-0: 0px;
    --spacing-space-10: 2px;
    --spacing-space-50: 4px;
    --spacing-space-100: 8px;
    --spacing-space-150: 12px;
    --spacing-space-200: 16px;
    --spacing-space-300: 24px;
    --spacing-space-400: 32px;
    --spacing-space-500: 40px;
    --spacing-space-550: 48px;
    --spacing-space-600: 56px;
    --spacing-space-650: 64px;
    --spacing-space-700: 72px;
    --spacing-space-750: 80px;
    --spacing-space-800: 96px;
    --spacing-space-900: 104px;
    --spacing-space-1000: 132px;

    // BORDER-RADIUS
    --rounded-0: 0px;
    --rounded-50: 4px;
    --rounded-100: 8px;
    --rounded-200: 16px;
    --rounded-300: 24px;
    --rounded-400: 32px;
    --rounded-500: 56px;
    --rounded-1000: 400px;

    --gapRow: var(--spacing-space-750);
    --gapColumn: var(--spacing-space-300);
    --gapRowModul: var(--spacing-space-400);
    --gap-heading-seccio: var(--spacing-space-300);

    background-color: var(--neutrals-0);
    color: var(--color-text-default);
    font-family: var(--font-family);

    strong {
        font-weight: bold;
    }

    // h1,
    // h2,
    // h3,
    // h4,
    // h5,
    // h6 {
    //     text-wrap: balance;
    // }

    p,
    li,
    figcaption {
        text-wrap: pretty;
        //max-width: 65ch;
    }

    header {
        display: contents;
    }

    :global {
        .row {
            padding: 0 16px;
            display: flex;
            align-items: center;
            max-width: 2144px;
            margin: 0 auto;
        }
        .centrat {
            display: flex;
            justify-content: center;
        }
    }

    // MIDES TEXT DEVICE S
    @media #{global.$VP_ALL_MOBILE} {
        --font-size-50: #{mixins.pxRem(10)};
        --font-size-100: #{mixins.pxRem(11)};
        --font-size-200: #{mixins.pxRem(12)};
        --font-size-300: #{mixins.pxRem(14)};
        --font-size-400: #{mixins.pxRem(16)};
        --font-size-450: #{mixins.pxRem(18)};
        --font-size-500: #{mixins.pxRem(20)};
        --font-size-550: #{mixins.pxRem(20)};
        --font-size-600: #{mixins.pxRem(20)};
        --font-size-650: #{mixins.pxRem(22)};
        --font-size-700: #{mixins.pxRem(23)};
        --font-size-800: #{mixins.pxRem(26)};
        --font-size-850: #{mixins.pxRem(28)};
        --font-size-900: #{mixins.pxRem(29)};
        --font-size-1000: #{mixins.pxRem(32)};
        --gapRow: var(--spacing-space-400);
        --gapRowModul: var(--spacing-space-300);

        --font-height-height-100: 1.4; //body-xs
        --font-height-height-200: 1.5; //body-s
        --font-height-height-300: 1.42857; //body-m
        --font-height-height-400: 1.375; //body-l
        --font-height-height-450: 1.44444; //body-xl
        --font-height-height-500: 1.4; //body-xxl
        --font-height-height-600: 1.45; //heading-s
        --font-height-height-700: 1.3913; //heading-m
        --font-height-height-800: 1.34615; //heading-l
        --font-height-height-900: 1.37931; //heading-xl
        --font-height-height-1000: 1.375; //heading-xxl
    }

    // MIDES TEXT DEVICE M
    @media #{global.$VP_TP} {
        --font-size-50: #{mixins.pxRem(10)};
        --font-size-100: #{mixins.pxRem(11)};
        --font-size-200: #{mixins.pxRem(12)};
        --font-size-300: #{mixins.pxRem(16)};
        --font-size-400: #{mixins.pxRem(18)};
        --font-size-450: #{mixins.pxRem(20)};
        --font-size-500: #{mixins.pxRem(23)};
        --font-size-550: #{mixins.pxRem(24)};
        --font-size-600: #{mixins.pxRem(26)};
        --font-size-650: #{mixins.pxRem(28)};
        --font-size-700: #{mixins.pxRem(29)};
        --font-size-800: #{mixins.pxRem(32)};
        --font-size-850: #{mixins.pxRem(34)};
        --font-size-900: #{mixins.pxRem(36)};
        --font-size-1000: #{mixins.pxRem(41)};
        --gapRow: var(--spacing-space-550);

        --font-height-height-100: 1.4; //body-xs
        --font-height-height-200: 1.5; //body-s
        --font-height-height-300: 1.375; //body-m
        --font-height-height-400: 1.44444; //body-l
        --font-height-height-450: 1.4; //body-xl
        --font-height-height-500: 1.26087; //body-xxl
        --font-height-height-600: 1.23077; //heading-s
        --font-height-height-700: 1.2069; //heading-m
        --font-height-height-800: 1.25; //heading-l
        --font-height-height-900: 1.36111; //heading-xl
        --font-height-height-1000: 1.36585; //heading-xxl
    }

    // MIDES TEXT DEVICE L
    @media #{global.$VP_REGULAR_AND_TL} {
        --font-size-50: #{mixins.pxRem(11)};
        --font-size-100: #{mixins.pxRem(12)};
        --font-size-200: #{mixins.pxRem(14)};
        --font-size-300: #{mixins.pxRem(16)};
        --font-size-400: #{mixins.pxRem(18)};
        --font-size-450: #{mixins.pxRem(20)};
        --font-size-500: #{mixins.pxRem(23)};
        --font-size-550: #{mixins.pxRem(24)};
        --font-size-600: #{mixins.pxRem(26)};
        --font-size-650: #{mixins.pxRem(28)};
        --font-size-700: #{mixins.pxRem(32)};
        --font-size-800: #{mixins.pxRem(41)};
        --font-size-850: #{mixins.pxRem(44)};
        --font-size-900: #{mixins.pxRem(46)};
        --font-size-1000: #{mixins.pxRem(58)};
        --gapRow: var(--spacing-space-650);

        --font-height-height-100: 1.66667; //body-xs
        --font-height-height-200: 1.57143; //body-s
        --font-height-height-300: 1.5; //body-m
        --font-height-height-400: 1.44444; //body-l
        --font-height-height-450: 1.4; //body-xl
        --font-height-height-500: 1.26087; //body-xxl
        --font-height-height-600: 1.23077; //heading-s
        --font-height-height-700: 1.25; //heading-m
        --font-height-height-800: 1.19512; //heading-l
        --font-height-height-900: 1.21739; //heading-xl
        --font-height-height-1000: 1.17241; //heading-xxl
    }

    @media (min-width: 1024px) {
        // --gapRow: 72px;

        :global {
            .row {
                padding: 0 34px;
            }
        }
    }
    @media (min-width: 1440px) {
        // --gapRow: 96px;

        :global {
            .row {
                padding: 0 56px;
            }
        }
    }

    :global {
        .gapBefore {
            margin-top: var(--spacing-space-300);
        }
        .gapAfter {
            margin-bottom: var(--spacing-space-300);
        }
        .noGapAfter {
            margin-bottom: 0 !important;
        }
        .rowfixed {
            margin: var(--spacing-space-300) var(--spacing-space-200) var(--spacing-space-400);
            width: auto;
        }
        .gapAfterRow {
            margin-bottom: var(--spacing-space-400);
        }
    }

    // a partir de 720
    @media #{global.$VP_BEYOND_MOBILE} {
        :global {
            .rowfixed {
                margin: var(--spacing-space-400) var(--spacing-space-500);
            }
            .gapAfterRow {
                margin-bottom: var(--spacing-space-550);
            }
        }
    }

    // a partir de 1304
    @media #{global.$VP_BEYOND_TP} {
        :global {
            .rowfixed {
                max-width: 1224px;
                margin: 0 auto;
                width: 100%;
            }
            .gapAfterRow {
                margin-bottom: var(--spacing-space-550);
            }
        }
    }

    // a partir 1680
    @media #{global.$VP_LARGE} {
        :global {
            .rowfixed {
                max-width: 1600px;
            }
        }
    }
}
