@use '../../../styles/_global-variables.scss' as global;

.content {
    min-height: 100vh;
    min-width: 100vw;
    background-color: #15191f;
}

.notfound {
    text-align: center;
    padding-top: 190px;
    padding-bottom: 32px;
}

.code {
    font-size: 62px;
    line-height: 46px;
    padding-bottom: 24px;
    position: relative;
    font-weight: 700;
}
.text {
    font-size: var(--headline_s);
    line-height: 1.2;
    padding-bottom: 48px;
}
.boto {
    font-size: var(--body_l);
    height: 56px;
    padding: 0 24px;
}

@media #{global.$VP_ALL_MOBILE} {
    .code {
        font-size: var(--headline_xl);
    }
    .text {
        padding-bottom: 40px;
    }
    .boto {
        font-size: var(--body_s);
        padding: 8px 16px;
        height: 40px;
    }
}
