@use '../../../styles/noticies/_global-variables.scss' as global;

.category {
    color: var(--color-text-link-neutral-default-inverse);
    font-size: var(--font-size-body-m);
    font-weight: 700;
    line-height: 1;
    padding: 0 0 1px;
    border-bottom: 1px solid var(--color-text-link-neutral-default-inverse);
    display: inline-block;
}

.boto {
    display: flex;
    height: 48px;
    min-width: 180px;
    padding: var(--spacing-space-0) var(--spacing-space-400);
    justify-content: center;
    align-items: center;
    border-radius: var(--rounded-1000);
    background: var(--color-background-button-3catinfo-default);
    color: var(--color-text-link-neutral-default);
    font-size: var(--font-size-body-m);
    font-style: normal;
    font-weight: 700;
    line-height: var(--font-height-body-m);
    text-wrap: nowrap;
    u {
        text-decoration: underline;
    }
    &:hover,
    &:focus {
        color: var(--color-text-link-3catinfo);
        background: var(--color-background-skeleton-neutral-default);
        border: 1px solid var(--color-text-link-3catinfo);
        border-radius: var(--rounded-1000);
    }
}

// M > 720
@media #{global.$VP_BEYOND_MOBILE} {
    .boto {
        height: 40px;
        padding: 0 var(--spacing-space-300);
    }
}

// L > 1304
@media #{global.$VP_BEYOND_TP} {
    .boto {
        height: 40px;
    }
}

// XL > 1920
@media #{global.$VP_LARGE} {
    .boto {
        height: 48px;
        padding: 0 var(--spacing-space-400);
    }
}
