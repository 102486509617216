// .----------------.  .----------------.  .----------------.  .----------------.  .----------------.
// | .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
// | |    ______    | || |   ______     | || |   _____      | || |      __      | || |  ____  ____  | |
// | |   / ____ `.  | || |  |_   __ \   | || |  |_   _|     | || |     /  \     | || | |_  _||_  _| | |
// | |   `'  __) |  | || |    | |__) |  | || |    | |       | || |    / /\ \    | || |   \ \  / /   | |
// | |   _  |__ '.  | || |    |  ___/   | || |    | |   _   | || |   / ____ \   | || |    \ \/ /    | |
// | |  | \____) |  | || |   _| |_      | || |   _| |__/ |  | || | _/ /    \ \_ | || |    _|  |_    | |
// | |   \______.'  | || |  |_____|     | || |  |________|  | || ||____|  |____|| || |   |______|   | |
// | |              | || |              | || |              | || |              | || |              | |
// | '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
//  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'
//
//  ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
//
// .----------------.  .----------------.  .----------------.  .----------------.
// | .--------------. || .--------------. || .--------------. || .--------------. |
// | |    ______    | || |     ______   | || |      __      | || |  _________   | |
// | |   / ____ `.  | || |   .' ___  |  | || |     /  \     | || | |  _   _  |  | |
// | |   `'  __) |  | || |  / .'   \_|  | || |    / /\ \    | || | |_/ | | \_|  | |
// | |   _  |__ '.  | || |  | |         | || |   / ____ \   | || |     | |      | |
// | |  | \____) |  | || |  \ `.___.'\  | || | _/ /    \ \_ | || |    _| |_     | |
// | |   \______.'  | || |   `._____.'  | || ||____|  |____|| || |   |_____|    | |
// | |              | || |              | || |              | || |              | |
// | '--------------' || '--------------' || '--------------' || '--------------' |
//  '----------------'  '----------------'  '----------------'  '----------------'

// COLORS
$colorFons100: #f8f7f7;
$colorFons90: #c8c7c7;
$colorFons80: #898888;

$colorText5: #222222;
$colorText20: #3d3d3d;
$colorText40: #7a7a7a;

$colorFilet80: #333;
$colorFilet60: #666;
$colorIcona: #fff;

$colorBase180: #5e0804;
$colorBase160: #9a0d2d;
$colorBase140: #8a100a;
$colorBase100: #e1261d;
$colorBase80: #be2649;
$colorBase70: #ee1547;
$colorBase60: #f0756e;
