@use '../../../styles/noticies/_global-variables' as global;

.modal {
    outline: 0;
    max-width: 100%;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;

    .inner {
        bottom: 0;
        left: 0;
        padding-top: 65px;
        position: relative;
        right: 0;
        top: 0;
    }

    &.full-screen {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;

        &::-webkit-scrollbar {
            display: none;
        }

        &.noControls {
            .inner {
                padding-top: 0;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                position: fixed;
                pointer-events: none;
            }
        }
    }

    &.flotant {
        pointer-events: none;

        .inner {
            padding-top: 0;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            position: fixed;
            pointer-events: none;
        }
    }
}

.controls {
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 401;
}

.controlsInner {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 28px 28px 0;
    pointer-events: none;
}

.iconaTancar {
    cursor: pointer;
    height: 32px;
    width: 32px;
    pointer-events: auto;
}
